import React from 'react';
import './whyInfenox.css';
import { Card, Row, Col } from 'react-bootstrap';
import timeImg from '../../../images/access_time_24px.png';
import dentist from '../../../images/dentistry_FILL0_wght400_GRAD0_opsz48 1.png';
import payment from '../../../images/payments_FILL0_wght400_GRAD0_opsz48 1.png';
import statergy from '../../../images/strategy_FILL0_wght400_GRAD0_opsz48 1.png';

function Index() {
  const cardItems = [
    { title: 'Competitive Salary', Image: payment },
    { title: 'Health & Dental', Image: dentist },
    { title: 'Targeted Bonus', Image: timeImg },
    { title: 'Progressive Vision', Image: statergy },
  ];

  return (
    <div>
      <div className="container_whyInfenox">
        <h3 className="qualities">Qualities</h3>
      </div>
      <div className="container_whyInfenox">
        <h1 className="heading">Why Infenox?</h1>
      </div>
      <div className="container_whyInfenox">
        <Row>
          {cardItems.map((item, index) => (
            <Col key={index}>
              <Card className="pb-4 mb-4  mx-auto ">
                <Card.Img src={item?.Image} className="fontImage" />
                <div className="cardTitle">{item?.title}</div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Index;
